<template>
    <section class="mb-5" id="product-details">
        <div class="container">
            <div class="row" v-if="loading">
                <div class="spinner-border text-primary mx-auto" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="row" v-if="open" id="model-selector">
                <div class="col-12">
                    <div class="row" v-if="makes && !selectedMake">
                        <div class="col-12 col-md-4 col-lg-3">
                            <div class="card p-4 h-100 cursor-pointer d-flex align-items-center justify-content-center" role="button" @click="reset">
                                <h2 class="text-primary">All Vans</h2>
                            </div>
                        </div>

                        <div class="col-12 col-md-4 col-lg-3" v-for="(make, index) in makes" :key="index">
                            <div class="card p-4 h-100 cursor-pointer" role="button" @click="getPrices(make)">
                                <img class="my-auto" :src="'/images/logos/'+make.toLowerCase()+'.png'" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="row" v-else-if="selectedMake && !selectedModel">
                        <div class="col-12 col-md-4 col-lg-3" v-for="(model, index) in models" :key="index">
                            <div class="card text-center p-4 h-100 cursor-pointer" role="button" @click="getPrices(selectedMake, model)">
                                <img class="my-auto" :src="'/storage/vehicles/'+model.make+'-'+model.model+'-'+model.year+'.jpg'" alt="">
                                <small class="mt-3">{{ model.make+' '+model.model+' '+model.year }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-else>
                <div class="col-12 col-lg-6">
                    <product-gallery :current="slide" :slides="gallery"/>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between">
                        <h1 class="h2">
                            {{ product.title }}
                        </h1>
    
                        <span v-if="options" class="badge fs-5 bg-primary rounded-pill">
                            {{ price }}
                        </span>
                    </div>

                    <p v-html="description"></p>

                    <form :action="route" method="POST">
                        <input name="_token" type="hidden" :value="csrf"/>
                        <input name="product" type="hidden" :value="product.id">

                        <div v-if="options && options.length > 1" class="form-group">
                            <label for="variants" class="text-muted small text-nowrap pe-3 mb-1">
                                Product Variant
                                <sup class="text-danger">*</sup>
                            </label>
                        </div>

                        <select :hidden="!options || options.length <= 1" class="form-select rounded-0 py-2" @change="selectVariant" required
                            name="variant" 
                            id="variant">
                                <option v-for="(variant, index) in options" :key="index" :value="variant.id" :data-slide="variant.metadata?.slide ?? null">
                                    {{ variant.nickname }} - {{ currency(variant.unit_amount / 100) }}
                                </option>
                        </select>

                        <div class="row my-2" v-for="(group, label) in addons" :key="label">
                            <div class="col-8">
                                <label :for="'addons'" class="mb-2 fw-semibold">{{ label }}</label>
                                <div :id="label" class="list-group">
                                    <div class="list-group-item d-flex" v-for="(option, index) in group.options" :key="index">
                                        <input class="form-check-input me-2"
                                               :type="group.multiple ? 'checkbox' : 'radio'"
                                               :name="'addons['+label+']['+option.product.name+']'"
                                               :value="option.id"
                                               :id="option.id"
                                               :data-label="option.product.name"
                                               @input="!group.multiple ? checkedAddon(label, option.product.name) : undefined"
                                        >
                                        <label class="form-check-label d-flex align-items-center justify-between w-100" :for="option.id">
                                            <span>{{ option.product.name }}</span>
                                            <span class="badge bg-primary ms-auto">{{ currency(option.unit_amount / 100) }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-lg-5">
                                <button type="button" @click="enquire()" class="btn btn-outline-primary w-100 mt-4 mb-2 mb-lg-0">
                                    Enquire
                                </button>
                            </div>

                            <div class="col-12 col-lg-7">
                                <button class="btn btn-primary w-100 mt-4 mb-2 mb-lg-0">
                                    Add to cart
                                </button>
                            </div>

                            <div class="col-12" v-if="makes && makes.length > 0">
                                <button type="button" @click="filter()" class="btn btn-outline-dark w-100 mt-4 mb-5">
                                    {{ selectedModel ? 'Change Van' : 'Find my van' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import axios from 'axios';
    import { marked } from 'marked';
</script>

<script>
    export default {
        name: "ProductPurchase",
        props: {
            route: {
                type: String
            },
            csrf: {
                type: String
            },
            product: {
                type: Object
            },
            gallery: {
                type: Object
            },
            variants: {
                type: Object
            },
            addons: {
                type: Object
            },
            makes: {
                type: Array,
                required: false,
                default: []
            }
        },
        data(){
            return {
                addonOptions: [],
                loading: false,
                open: false,
                models: [],
                selectedMake: null,
                selectedModel: null,
                options: [],
                slide: 0
            }
        },
        computed: {
            price(){
                if(this.options.length == 0){
                    return 'TBC';
                }

                const price = this.currency(this.options[0].unit_amount / 100, 0);
                return Object.keys(this.options).length > 1 ? 'From '+price : price;
            },

            description(){
                return marked(this.product.short_description);
            }
        },
        methods: {
            currency(value){
                return Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                }).format(value);
            },

            filter(){
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth',
                });

                this.selectedMake = null;
                this.selectedModel = null;
                this.open = true;
            },

            getPrices(make, model = null){
                this.loading = true;
                const self = this;
                this.selectedMake = make;
                this.selectedModel = model;

                axios.post('/api/prices/search', {
                    make: self.selectedMake,
                    model: self.selectedModel ? self.selectedModel.model : null,
                    year: self.selectedModel ? self.selectedModel.year : null
                })
                .then(function (response) {
                    self.models = response.data.models;
                    self.options = response.data.prices;

                    if(model){
                        self.open = false;
                    }

                    self.loading = false;
                })
                .catch(function (error) {
                    console.error(error);
                });
            },

            selectVariant(event){
                const selectedOption = event.target.options[event.target.selectedIndex]
                const index = selectedOption.getAttribute('data-slide')
                this.slide = index ? Number(index) : null
            },

            checkedAddon(label, option){
              const group = document.getElementById(label)
              const radios = group.querySelectorAll('input[type="radio"]')

                radios.forEach(radio => {
                    if(radio.dataset.label !== option){
                      radio.checked = false
                    }
                })
            },

            reset(){
                this.selectedMake = null;
                this.selectedModel = null;
                this.options = this.variants;
                this.open = false;
            },

            enquire(){
                document.getElementById('enquire').scrollIntoView();
            }
        },
        mounted(){
            this.options = this.variants;
        }
    }
</script>